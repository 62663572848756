<template>
    <div class="repairDetails">
        <rxNavBar  title="维修完成" ></rxNavBar>
        <div class="content">
            <div class="part">
                <span class="part-subtitle">报修详情</span>
                <div class="otherExpenses-inputpart">
                    <div class="otherExpenses-inputpart-row">
                        <div class="otherExpenses-address">{{repairDetail.roomDetailedAddress}}</div>
                    </div>
                    <div class="otherExpenses-inputpart-column">
                        <div class="otherExpenses-inputpart-row-bottom">
                            <div class="otherExpenses-inputpart-row-left">
                                <span class="part-inputpart-row-header">报修人：</span>
                                <span>{{repairDetail.applicationGlobaluserName ? repairDetail.applicationGlobaluserName : '暂无'}}</span>
                            </div>
                            <div class="otherExpenses-inputpart-row-right">
                                <span class="part-inputpart-row-header">联系方式：</span>
                                <span>{{repairDetail.applicationGlobaluserMoblile ? repairDetail.applicationGlobaluserMoblile : '暂无'}}</span>
                            </div>
                        </div>
                        <div class="otherExpenses-inputpart-row-bottom">
                            <div class="otherExpenses-inputpart-row-left">
                                <span class="part-inputpart-row-header">订单来源：</span>
                                <span>{{repairDetail.costBear ? repairDetail.costBear : '暂无'}}</span>
                            </div>
                            <div class="otherExpenses-inputpart-row-right">
                                <span class="part-inputpart-row-header">维修程度：</span>
                                <span class="part-inputpart-row-redtext">
                                    {{repairDetail.repairStatus ? repairDetail.repairStatus : '暂无'}}</span>
                            </div>
                        </div>
                        <div class="otherExpenses-inputpart-row-bottom">
                            <span class="part-inputpart-row-header">维修类型：</span>
                            <span>{{repairDetail.dictionaryTitle ? repairDetail.dictionaryTitle : '暂无'}}</span>
                        </div>
                        <div class="otherExpenses-inputpart-row-bottom">
                            <span class="part-inputpart-row-header">预约时间：</span>
                            <span class="part-inputpart-row-redtext">
                                {{repairDetail.appointmentTime ? repairDetail.appointmentTime : '暂无' }}</span>
                        </div>
                        <div class="repaireRemarksDiv">
                            <span class="part-inputpart-row-header">维修描述：</span>
                            <span class="part-inputpart-row-wrap">{{repairDetail.afterRentDesprition ? repairDetail.afterRentDesprition : '暂无'}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!--      申请详情的图片  -->
            <div class="applyDetailImgs" v-if="applyImgList.length>0">
                <van-swipe :loop="false" :width="115" :show-indicators="false">
                    <van-swipe-item   v-for=" (item,i) in applyImgList" :key="item.id">
                        <div class="applyDetailImg">
                            <img  :src="item.path" @click="onChangeApply(i)"/>
                        </div>
                    </van-swipe-item>
                    <div class="custom-indicator">
                        {{applyImgList.length}} 图
                    </div>
                </van-swipe>
            </div>
            <van-image-preview v-model="showApply" :images="applyDetailImgList"  :start-position="start"></van-image-preview>
            <div class="part">
                <div class="otherExpenses-inputpart">
<!--                    <span class="part-subtitle">报修详情</span>-->
                    <div class="otherExpenses-inputpart-column" style="margin-top: 0">
                        <div class="otherExpenses-inputpart-row-bottom">
                            <span class="part-inputpart-row-header">维修人员：</span>
                            <span>{{finishDetail.userName ? finishDetail.userName :'暂无'}}</span>
                        </div>
                        <div class="otherExpenses-inputpart-row-bottom">
                            <span class="part-inputpart-row-header">预计上门时间：</span>
                            <span class="part-inputpart-row-redtext">
                                {{finishDetail.lastDispatchTime ? finishDetail.lastDispatchTime :'暂无'}}</span>
                        </div>
                        <div class="otherExpenses-inputpart-row-bottom">
                            <span class="part-inputpart-row-header">预计费用：</span>
                            <span>{{finishDetail.expectFee ?finishDetail.expectFee+'元' :'暂无'}} </span>
                        </div>
                    </div>
                </div>
                <div class="part-inputpart-row">
                    <span :class=" !lastFee ? 'content-none' : 'content-have' "></span>
                    <span class="part-inputpart-row-header">完成费用</span>
                    <span class="content-divide">|</span>
                    <input type="number" v-model="lastFee">
                    <span class="part-inputpart-row-right" style="color: #d8d8d8">元</span>
                </div>
                <div id="finishTimePanel" class="part-inputpart-row" @click="clickFinishTimeShow">
                    <span :class=" 0==finishTime.trim().length ? 'content-none' : 'content-have' "></span>
                    <span class="part-inputpart-row-header">完成时间</span>
                    <span class="content-divide">|</span>
                    <span class="width">{{finishTime}}</span>
                    <img class="part-inputpart-row-right part-inputpart-row-right-timepick" src="../../../assets/images/timepick.png">
                </div>
                <div>
                    <van-popup v-model="isFinishTimeShow" position="bottom" class="selectTimePopup">
                            <van-datetime-picker v-model="currentDate" type="datetime" title="选择年月日" item-height="35px" @confirm="finishTimeConfirm" @cancel="finishTimeCancel"/>
                    </van-popup>

                </div>
                <div class="part-inputpart">
                    <div class="part-inputpart-textarea">
                        <div class="part-inputpart-row">
                            <span class="otherExpenses-row-enablenon part-inputpart-row-header">处理反馈</span>
                            <span class="smallgraytext">(非必填)</span>
                        </div>
                        <textarea v-model="afterRentDesprition"></textarea>
                    </div>
                    <div class="part-uploadpic-content">
                        <!--           上传图片按钮-->
                        <van-uploader class="part-uploadpic-module"  v-model="imgList" multiple :max-count="10" :after-read="afterReadTest" image-fit="contain">
                            <div class="part-inputpart-cardpic-button">
                                <span class="part-inputpart-cardpic-text">上传图片</span>
                            </div>
                        </van-uploader>
                    </div>
                </div>
                <button @click="saveAfterRenBtn" class="part-button" :class=" 0==lastFee.length || 0==finishTime.length ? '' : 'part-button-enabled' "
                            :disabled="!lastFee||!finishTime||disabled">提交</button>
            </div>
        </div>
<!--        <van-loading class="vanLoading" type="spinner" color="#1989fa" v-if="loading" />-->
<!--        <van-overlay class="vanOverlay" :show="loading"  />-->
        <loadingFlag v-if="loadingFlag"></loadingFlag>
    </div>
</template>

<script>
    import {
        NavBar, Image as VanImage, ImagePreview, DatetimePicker, Uploader, Popup, Swipe,SwipeItem,Loading,Overlay
    } from 'vant'
    import loadingFlag from "./LoadingFlag";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import {getStaffId, globaluserId,dealImage, responseUtil} from "../../../libs/rongxunUtil";
    import {
        base64Upload,
        queryAfterRentRepair,
        repairFinishDetail,
        saveAfterRentRepair
    } from "../../../getData/getData";
    import Vue from "vue";
    Vue.use(Popup);
    export default {
        name: "RepairDetails",

        components: {
            [NavBar.name]: NavBar,
            [DatetimePicker.name]: DatetimePicker,
            [Uploader.name]: Uploader,
            [VanImage.name]:VanImage,
            [Popup.name]: Popup,
            [ImagePreview.Component.name]: ImagePreview.Component,
            rxNavBar,
            [Swipe .name]:Swipe,
            [SwipeItem .name]:SwipeItem,
            [Loading .name]:Loading,
            [Overlay .name]:Overlay,
            loadingFlag
        },

        data() {
            return {
                roleType: '1',  //标识，用于判断入口是我的页面的租后工单（装修工人等 是 0   否 1）
                loadingFlag : false,
                disabled : false,
                //============================报修详情===============
                //初始化时用的租后ID
                id : '',
                //初始化返回的详情信息
                repairDetail : {},
                isFinishTimeShow: false,
                currentDate: new Date(),
                applyImgList : [],
                // 反馈图片列表
                imgList: [],
                showApply : false,
                applyDetailImgList : [],
                start : 0,
                //===========================维修详情===============
                //维修详情初始化数据
                finishDetail : {},
                // 完成费用
                lastFee: '',
                // 完成时间
                finishTime: '',
                // 处理反馈
                afterRentDesprition: '',
            }
        },
        created() {
            this.id = this.$route.query.id
            this.repair_id = this.$route.query.repair_id
            this.roleType = this.$route.query.roleType  //是否是装修负责人  0 是    1 否
            this.queryAfterRentRepair()
            this.repairFinishDetail()
        },
        methods: {
            onChangeApply(i) {
                this.start=i
                this.showApply=true
            },
            //返回上一层

            //报修详情初始化
            queryAfterRentRepair(){
                let that = this
                let initData = {}
                initData.id = that.id
                queryAfterRentRepair(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                        that.repairDetail = response.data.data
                        that.applyImgList = response.data.data.imgList
                        for(let i=0;i<that.applyImgList.length;i++){
                            that.applyDetailImgList.push(that.applyImgList[i].path)
                        }
                    })
                })
            },
            //维修完成维修详情初始化
            repairFinishDetail(){
                let that = this
                let initData = {}
                initData.user_id=globaluserId()
                initData.id = that.repair_id
                repairFinishDetail(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                        that.finishDetail = response.data.data.data
                        that.lastFee = that.finishDetail.expectFee
                        console.log(that.finishDetail)
                    })
                })
            },
            //维修完成保存接口
            saveAfterRenBtn(){
                this.disabled = true
                this.saveAfterRentRepair()
                setTimeout(()=>{
                    this.disabled = false
                },2000)

            },
            //维修完成保存接口
            saveAfterRentRepair (){
                let that = this
                let initData = {}
                if(that.roleType == '1'){
                    initData.staff_id =  getStaffId()
                }else if(that.roleType == '0'){
                    initData.staff_id = '1'  //如果登录人是装修负责人（非员工），则使用传固定值“1”
                }
                // initData.staff_id =  getStaffId()
                initData.id =that.id
                initData.description_id = that.repairDetail.description_id
                initData.lastFee = that.lastFee
                initData.finishTime = new Date(that.finishTime)
                initData.afterRentDesprition = that.afterRentDesprition
                initData.imgList = that.imgList
                console.log(initData)
                saveAfterRentRepair(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                        responseUtil.alertMsg(that,'维修完成')
                        that.skip()
                    })
                })
            },

            //图片多选上传
            afterReadTest(file){
                // this.loadingFlag = true;
                let fileLength = parseInt(file.length) - parseInt(1)
                if(file.length > 1){
                    for(let i = 0; i<file.length;i++){
                        let fileDetail = file[i];
                        dealImage(fileDetail,fileDetail.content,400,this.afterRead,i,fileLength);
                    }
                }else{
                    dealImage(file,file.content,400,this.afterRead);
                }
            },

            afterRead(file,base64Str,i,fileLength){
                let that = this
                let initData = {
                    base64: base64Str
                }
                that.loadingFlag = true,
                    base64Upload(initData).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            file.path = response.data.data.path
                            // that.loadingFlag = false
                            if(i == fileLength){
                                that.loadingFlag = false
                            }
                        })
                    })
            },

            //维修完成跳转页面
            skip(){
                this.$router.go(-1)
            },


            //完成时间
            clickFinishTimeShow() {
                this.isFinishTimeShow = !this.isFinishTimeShow;
                var panel = document.getElementById('finishTimePanel')
                if(panel){
                    document.addEventListener('click',e =>{
                        if(!panel.contains(e.target)){
                            this.isFinishTimeShow = false
                        }
                    })
                }
            },
            finishTimeConfirm(value){
                var date = new Date(value)
                this.finishTime = this.formatDate(date)
                this.isFinishTimeShow = false
            },
            // 格式化日期方法
            formatDate(dateTime) {
                console.log(dateTime.getMinutes())
                let year = `${dateTime.getFullYear()}`;
                let month  = `${dateTime.getMonth() + 1}` ;
                let date = `${dateTime.getDate()}` ;
                let hours = `${dateTime.getHours()}` ; ;
                let minutes = `${dateTime.getMinutes()}` ; ;
                if(month < 10){
                    month = `0${dateTime.getMonth() + 1}`
                }
                if(date < 10){
                    date = `0${dateTime.getDate()}`
                }
                if(hours < 10){
                    hours = `0${dateTime.getHours()}`
                }
                if( minutes < 10){
                    minutes = `0${dateTime.getMinutes()}`
                }
                return year+ '/' + month+ '/' + date + ' ' + hours + ':' + minutes;

            },
            finishTimeCancel(){
                this.isFinishTimeShow = false
            },

            previewImg(index){
                ImagePreview(
                    {
                        images: this.acceptComplaintsInfo.picList,
                        startPosition: index,
                        onClose() {
                            // do something
                        },
                    }
                )
            },
        }

    }
</script>

<style scoped lang="less">
    .repaireRemarksDiv{
        word-wrap: break-word;
    }
    .content {
        font-size: 14px;
    }

    .part {
        margin: 15px 15px 0 ;
    }

    .part-subtitle {
        color: #ff5d3b;
        font-size: 12px;
        font-weight: 900;
        position: relative;
        top: 8px;
    }

    .part-inputpart {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
    }

    .part-inputpart-row {
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        align-items: center;
        height: 45px;
        background-color: #ffffff;
        margin-top: 15px;
    }

    .part-inputpart-row-header {
        font-weight: 900;
        font-size: 15px;
        white-space: nowrap;
    }

    .otherExpenses-inputpart {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        overflow: hidden;
        margin-top: 10px;
    }

    .otherExpenses-inputpart-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #ffffff;
        margin-top: 2px;
    }

    .part-inputpart-row input {
        border: 0;
        width: 100%;
    }

    .otherExpenses-address{
        padding: 15px 10px 15px 15px;
        font-size: 16px;
        font-weight: 900;
    }

    .otherExpenses-inputpart-row-bottom {
        margin-bottom: 5px;
        display: flex;
        align-items: baseline;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .otherExpenses-inputpart-row-left {
        display: inline-block;
        width: 47%;
        overflow: hidden;
        /*padding-right: 15px;*/
        margin-right: 5px;
    }

    .otherExpenses-inputpart-row-right {
        display: inline-block;
        width: 53%;
    }

    .otherExpenses-inputpart-column {
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 16px 15px;
        justify-content: space-between;
        background-color: #ffffff;
        margin-top: 2px;
    }

    .part-inputpart-row-right {
        float: right;
        margin-right: 15px;
    }

    .part-inputpart-row-wrap {
        line-height: 24px;
    }

    .content-have {
        padding: 3px;
        margin: 0 10px 0 15px;
        border-radius: 50%;
        background-color: #ff5d3b;
    }

    .content-none {
        padding: 3px;
        margin: 0 10px 0 15px;
        border-radius: 50%;
        background-color: #c7c7c7;
    }

    .content-divide {
        font-size: 12px;
        color: #efefef;
        margin: 0 10px;
    }

    .otherExpenses-row-enablenon{
        margin-left: 15px;
    }

    .part-inputpart-row-graytext {
        color: #d8d8d8;
        width: 100%;
    }

    .part-inputpart-row-normaltext {
        color: black;
        font-weight: 900;
    }

    .part-inputpart-row-redtext {
        width: 100%;
        color: #ff5d3b;
    }

    .part-inputpart-textarea {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        background-color: white;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .part-inputpart-textarea div:first-child {
        margin-top: 0;
    }

    .part-inputpart-textarea textarea {
        height: 60px;
        background-color: #FaFaFa;
        border-radius: 8px;
        padding: 10px;
        margin: 0px 15px;
        border: none;
        resize: none;
    }

    .part-inputpart-textarea textarea::-webkit-input-placeholder {
        color: #d8d8d8;
    }

    .part-button {
        height: 45px;
        border-radius: 10px;
        background-color: #ededed;
        color: #ffffff;
        text-align: center;
        line-height: 45px;
        font-size: 15px;
        font-weight: 900;
        margin-top: 40px;
        margin-bottom: 50px;
        width: 100%;
        border: none;
    }

    .part-button-enabled {
        background-image: linear-gradient(to right, #ffc274, #ff5d3b);
    }

    .smallgraytext{
        color: #D8D8D8;
        font-size: 12px;
        margin-bottom: -3px;
    }

    .part-inputpart-row-right-timepick{
        width: 20px;
    }

    .part-inputpart-dropDownList{
        height: 23%;
        /*垂直方向滚动*/
        /*overflow-y: scroll;*/
        overflow: auto;
        width: 92.5%;
        position: absolute;
        z-index: 99;
    }

    .part-uploadpic-content{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: white;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        width: 100%;
        margin: 0 auto;
    }

    .part-uploadpic-module{
        width: 100%;
        margin: 20px 8px;
    }

    .part-uploadpic-module-pic{
        width: 80px;
        height: 80px;
        border-radius: 8px;
    }

    .part-inputpart-cardpic-text{
        position: absolute;
        top: 72px;
        left: 33px;
        font-size: 12px;
        color: #d8d8d8;
    }

    .part-inputpart-cardpic-button{
        display: inline-block;
        background: url("../../../assets/images/grayAdd.png") center center no-repeat;
        width: 90px;
        height: 90px;
        border-radius: 8px;
        background-size: 30px;
        background-color: #fafafa;
        margin-left: 10px;
        margin-top: 10px;
    }

    .width{
        width: 100%;
    }

    .selectTimePopup{
        height: 6rem;
        width: 100%;
    }
    /*    申请详情图片DIV*/
    .applyDetailImgs{
        position: relative;
        height: 108px;
        width: 92%;
        margin: 15px auto ;
        background-color: #FFFFFF;
        border-radius: 8px;
    /*图片*/
    .applyDetailImg{
        width: 100px;
        height: 75px;
        border-radius: 8px;
        margin: 15px 0px 18px 4px ;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e1eaec;
    img{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
    }
    /*    轮播的显示图片数*/
    .custom-indicator{
        position: absolute;
        left: 15px;
        top: 20px;
        padding: 1px 7px;
        font-size: 12px;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        color: white;
    }
    }
    .vanLoading{
        position: fixed;
        z-index: 101;
        top: 50%;
        left: 47%;
    }
    .vanOverlay{
        z-index: 100;
    }
</style>
